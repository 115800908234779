<template>
  <div class="ml-4 mr-4 canvas-height">
    <canvas id="situacao-chart" ></canvas>
  </div>
</template>

<script>
//import { Chart, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js/auto'
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment'; // or another adapter to avoid moment
Chart.register(...registerables);
import situacaoChartData from './GraficosJs/situacao.js'

export default {
  name: 'SituacaoChart',
  data() {
    return {
      situacaoChartData: situacaoChartData
    }
  },

  mounted() {
     const ctx = document.getElementById('situacao-chart');
     new Chart(ctx, this.situacaoChartData);
  }
}

</script>

<style scoped>
  .canvas-height {
    height:432px;
  }

  @media (max-width: 1024px) {
    .canvas-height {
      height:300px;
    }
  }

</style>