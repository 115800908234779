import { render, staticRenderFns } from "./PainelProprietario.vue?vue&type=template&id=46aa6f78&scoped=true"
import script from "./PainelProprietario.vue?vue&type=script&lang=js"
export * from "./PainelProprietario.vue?vue&type=script&lang=js"
import style0 from "./PainelProprietario.vue?vue&type=style&index=0&id=46aa6f78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46aa6f78",
  null
  
)

export default component.exports