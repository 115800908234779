import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment'; // or another adapter to avoid moment
Chart.register(...registerables);
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

export const barChartData = {
    type: "bar",
    // data: {
    //     labels: ["Mercury", "Venus", "Earth", "Mars", "Jupiter", "Saturn", "Uranus", "Neptune"],
    //     datasets: [{
    //             label: "Number of Moons",
    //             data: [0, 0, 1, 2, 79, 82, 27, 14],
    //             backgroundColor: "rgba(54,73,93,.5)",
    //             borderColor: "#36495d",
    //             borderWidth: 3
    //         },
    //         {
    //             label: "Planetary Mass (relative to the Sun x 10^-6)",
    //             data: [0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514],
    //             backgroundColor: "rgba(71, 183,132,.5)",
    //             borderColor: "#47b784",
    //             borderWidth: 3
    //         }
    //     ]
    // },
    data: {
        labels: [ ],
        datasets: [{
            label: '',
            data: [],
            backgroundColor: [],
            hoverOffset: 4
        }, ]
    },
    // options: {
    //     indexAxis: "y",
    //     responsive: true,
    //     lineTension: 1,
    //     scales: {
    //         yAxes: [{
    //             ticks: {
    //                 beginAtZero: true,
    //                 padding: 25
    //             }
    //         }]
    //     }
    // }
    options: {
        legend: false,
        indexAxis: 'x',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
            bar: {
                borderWidth: 2,
            }
        },
        responsive: true,
        plugins: {
            title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart'
            }
        }
    },
};

// data {
//     "tipo": [
//         { "TERRENO": 210 },
//         { "CASA": 90 }
//     ],
//     "localizacao": [
//         { "FRANCA": 280 },
//         { "CRISTAIS PAULISTA": 10 },
//         { "RIFAINA": 9 }
//     ],
//     "status": [
//         { "PRONTO PARA MORAR": 80 },
//         { "EM OBRAS": 11 }
//     ],
//     "preco": [
//         { "0 - 50.000": 80 },
//         { "50.001 - 80.000": 11 },
//         { "80.001 - 110.000": 31 },
//         { "110.001 - 140.000": 94 },
//         { "140.001 - 180.000": 120 },
//         { "180.001 - 200.000": 126 },
//         { "200.001 - 240.000": 20 },
//         { "240.001 - 280.000": 50 },
//         { "280.001 - 320.000": 66 },
//         { "320.001 - 380.000": 34 },
//         { "380.001 - 450.000": 45 },
//         { "450.001 - 550.000": 67 },
//         { "550.001 - 700.000": 34 },
//         { "700.001 - 1.000.000": 78 },
//         { "1.000.001 - 1.500.000": 12 },
//         { "Acima de 1.500.000": 6 }
//     ],
//     "area": [
//         { "0 - 100.00": 40 },
//         { "100.01 - 150.00": 101 },
//         { "150.01 - 200.00": 301 },
//         { "200.01 - 250.00": 94 },
//         { "250.01 - 300.00": 20 },
//         { "300.01 - 350.00": 26 },
//         { "350.01 - 400.00": 60 },
//         { "400.01 - 480.00": 50 },
//         { "180.01 - 600.00": 69 },
//         { "600.01 - 1000.00": 84 },
//         { "1000.01 - 450.000": 120 },
//         { "450.001 - 550.000": 14 },
//         { "550.001 - 1.000.00": 34 },
//         { "1.000.01 - 2.000.00": 90 },
//         { "2.000.01 - 5.000.00": 102 },
//         { "Acima de 5.000.00": 6 }
//     ]
// }

export default barChartData;
