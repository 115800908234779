<template>
  <div id="PainelProprietario">
    <div class="main-title headline lighten-2 pt-0 pb-9 px-1" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
      <!-- <v-app-bar flat color="rgba(0, 0, 0, 0)" c> -->
        <v-toolbar-title ref="titulo_cabecalho" id="titulo_cabecalho" class="text-white pt-3 pb-4 ml-4 mb-n3">
          Painel do Proprietário
        </v-toolbar-title>
      <!-- </v-app-bar> -->

      <div class="d-flex align-center w-100 mb-3">
        <v-app-bar flat color="rgba(0, 0, 0, 0)" >
            <v-select
              :items="empreendimentos"
              v-model="empreendimento_selecionado"
              item-text="empreend_nome"
              item-value="cod_empreendimento"
              label="Empreendimento"
              filled
              dense
              required
              flat
              solo-inverted
              clearable
              class="search-input"
              dark
              v-bind:class="{'search-input-longo': search_input_focus}"
              @focus="onFocus($event)"
              @blur="onBlur($event)"
              tabindex="-1"
            ></v-select>
            <v-spacer></v-spacer>
            <v-menu :close-on-content-click="false"
                    open-on-hover
                    bottom
                    offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  ref="btnOpcoes"
                  :small="isMobile"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  dark
                  class="ml-n5"
                >
                  <v-icon class="mr-1" color="light-blue accent-2">mdi-view-headline</v-icon>
                  <span align="right">Opções</span>
                </v-btn>
              </template>

              <!-- Menus de Relatorios -->
              <v-card elevation="0" style="min-width:400px">
                <v-card-text>

                  <v-treeview
                    ref="tree"
                    v-model="tree_opcoes"
                    :items="menu_config"
                    :open-all="false"
                    item-key="name"
                    activatable
                    return-object
                    open-on-click
                    dense
                    hoverable
                    expand-icon="mdi-chevron-down"
                    on-icon="mdi-chevron"
                    off-icon="mdi-chevron"
                    indeterminate-icon="mdi-chevron"
                    @update:open="openNode"
                    @update:active="abreRelatorioExterno"
                  >
                    <template class="ml-n6" v-slot:prepend="{ item }">
                      <!-- <v-icon
                        v-if="item.children">
                        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                      </v-icon> -->
                      <v-icon
                        clsss="pa-0"
                        v-if="!item.children">
                        {{ item.icon }}
                      </v-icon>
                    </template>
                  </v-treeview>
                </v-card-text>
              </v-card>
            </v-menu>
        </v-app-bar>
      </div>
  </div>

    <v-container class="container-table mt-n11 pa-5" style="background-color:#E5E5E5">
      <!-- CARDS -->
      <v-row class="mt-0 w-100 ma-0">

        <!-- CARDS COLORIDOS -->
        <v-card class="card-container w-100 d-flex flex-row" elevation="1">
          
          <v-col cols="6" lg="2" md="4">
            <v-card class="card-price card-size">
              <v-card-text class="body-2" style="height: 45px"
                >Qtde Vendas
                <v-icon class="icon-dashboard">mdi-currency-usd</v-icon>
              </v-card-text>
              <table class="ml-2 mt-1" width="100%">
                <tr>
                  <td>
                    <div align="center" class="body-2">Vista<br>{{ resumo_financeiro.tot_qtde_vendas_avista }}</div>
                  </td>
                  <td>
                    <div class="body-2">Prazo<br>{{ resumo_financeiro.tot_qtde_vendas_parcelado }}</div>
                  </td>
                  <td>
                    <div class="body-2">Total<br>{{ parseFloat(resumo_financeiro.tot_qtde_vendas_avista) + parseFloat(resumo_financeiro.tot_qtde_vendas_parcelado) }}</div>
                  </td>
                </tr>
              </table>
            </v-card>
          </v-col>

          <v-col cols="6" lg="2" md="4">
            <v-card class="card-type card-size">
              <v-card-text class="body-2" style="height: 45px"
                >Total (Á Vista)<v-icon class="icon-dashboard">mdi-currency-usd</v-icon>
              </v-card-text
              ><div class="mt-3"><span class="body-3">R$ {{formatNumber(resumo_financeiro.tot_avista)}}</span></div>
            </v-card>
          </v-col>

          <v-col cols="6" lg="2" md="4">
            <v-card class="card-location card-size">
              <v-card-text class="body-2" style="height: 45px"
                >Total (Entradas)<v-icon class="icon-dashboard"
                  >mdi-currency-usd</v-icon
                ></v-card-text
              ><div class="mt-3"><span class="body-3">R$ {{ formatNumber(resumo_financeiro.tot_entrada) }}</span></div>
              </v-card>
          </v-col>

          <v-col cols="6" lg="2" md="4">
            <v-card class="card-status card-size">
              <v-card-text class="body-2" style="height: 45px"
                >Total (Parcelas)<v-icon class="icon-dashboard"
                  >mdi-currency-usd</v-icon
                ></v-card-text
              ><div class="mt-3"><span class="body-3">R$ {{ formatNumber(resumo_financeiro.tot_parcela) }}</span></div>
              </v-card>
          </v-col>

          <v-col cols="6" lg="2" md="4">
            <v-card class="card-type card-size">
              <v-card-text class="body-2" style="height: 45px"
                >Total (Juros)<v-icon class="icon-dashboard"
                  >mdi-currency-usd</v-icon
                ></v-card-text
              ><div class="mt-3"><span class="body-3">R$ {{ formatNumber(resumo_financeiro.tot_juros_financiamento) }}</span></div>
            </v-card>
          </v-col>

          <v-col cols="6" lg="2" md="4">
            <v-card class="card-price card-size">
              <v-card-text class="body-2" style="height: 45px"
                >Total (Contrato)
                <v-icon class="icon-dashboard"
                  >mdi-currency-usd</v-icon
                > </v-card-text
              ><div class="mt-3"><span class="body-3">R$ {{ formatNumber(resumo_financeiro.tot_contrato) }}</span></div>
            </v-card>
          </v-col>

        </v-card>
      </v-row>
      <!-- FIM CARDS COLORIDOS -->

      <!-- GRAFICOS -->
      <v-row class="mt-3 w-100 ma-0">
        <v-col sm="12" lg="6" class=" pl-0 col-situacao">
          <v-card class="pb-4 height"  elevation="1">
            <v-card-text class="text-h6"><span class="cor_titulo_painel">Situação dos Imóveis</span></v-card-text>
            <SituacaoChart />
          </v-card>
        </v-col>

        <v-col sm="12" lg="6" class="col-inadimplencia pr-0">
          <v-card class="min-height pb-4" elevation="1">
            <v-app-bar flat color="rgba(0, 0, 0, 0)" >
              <v-toolbar-title class="text-h6 pl-0 cor_titulo_painel">
                Inadimplência
              </v-toolbar-title>
              <!-- <v-spacer></v-spacer>
              <v-btn
                color="primary"
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn> -->
            </v-app-bar>
            
            <v-card-text class="date-mobile h6 pt-0 mb-0 pb-0">
              <table class="d-flex">
                <tr class="row-date">
                  <td class="label-date-solo">
                    <label class="mb-1 mr-4 h6 label-date">De </label>
                  </td>
                  <td class="td-mobile">
                    <label class="mt-3 mr-6 h6 d-flex d-sm-none label-date">De </label>
                    <v-text-field
                      class="data-input"
                      type="date"
                      v-model="data_inadimplencia_inicial"
                      dense
                      :value="data_inadimplencia_inicial"
                    ></v-text-field>
                  </td>
                  <td class="label-date-solo">
                    <label class="mb-1 ml-4 mr-4 h6 label-date">Até </label>
                  </td>
                  <td class="td-mobile">
                    <label class="mt-3 mr-5 h6 d-flex d-sm-none label-date">Até </label>

                    <v-text-field
                      class="data-input"
                      type="date"
                      v-model="data_inadimplencia_final"
                      dense
                      :value="data_inadimplencia_final"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-card-text>

            <div class="ml-4 mr-4">
              <!-- <canvas id="grafico-barra" height="300" max-height="300px" style="position: relative; height:40vh; width:80vw"></canvas> -->
              <v-data-table
                :headers="headers"
                :items="inadimplencia"
                disable-pagination
                fixed-header
                :hide-default-header="isMobile"
                class="elevation-0"
                style="margin-top:-30px"
                mobile-breakpoint="0"
                height="330"
                max-height="330"
                :loading="loading_inadimplencia"
                fixed-fooder
                hide-default-footer
                dense
              >
                <template v-slot:body="{ items }">
                  <tbody v-if="!isMobile">
                    <tr v-for="item in items" :key="item.id" height="20px">
                      <td align="center" class="col-font" style="width: 15%">
                        {{ item.mes_ano }}
                      </td>
                      <td align="right" style="width: 28%">
                        <span class="col-font"
                          v-if="parseFloat(item.tot_vl_entr) > 0">
                          R$ {{ formatNumber(parseFloat(item.tot_vl_entr)) }} ({{ item.quant_entr }})
                        </span>
                        <span v-else>
                          -
                        </span>
                      </td>
                      <td align="right" class="col-font" style="width: 28%">
                        <span class="col-font"
                          v-if="parseFloat(item.tot_vl_parc) > 0">
                          R$ {{ formatNumber(parseFloat(item.tot_vl_parc)) }} ({{ item.quant_parc }})
                        </span>
                      </td>
                      <td align="right" class="col-font" style="width: 28%">
                        R$ {{ formatNumber(parseFloat(item.total_geral)) }} ({{ item.quant_geral }})
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-if="isMobile">
                    <tr v-for="item in items" :key="item.id" class="w-100 d-flex flex-column justify-space-between py-2" style="height: 165px;">
                      <td class="w-100 d-flex flex-column justify-space-between mb-0 pt-2 pb-5" style="height: 165px;">
                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Mês/Ano</span>
                          <span class="mr-3 body-2">{{ item.mes_ano }}</span>
                        </div>

                        <div class="d-flex justify-space-between" style="">
                          <span class="font-weight-bold body-2 ml-3"
                          >Mês/Ano</span>
                          <span class="mr-3 body-2" v-if="parseFloat(item.tot_vl_entr) > 0">
                          R$ {{ formatNumber(parseFloat(item.tot_vl_entr)) }} ({{ item.quant_entr }})</span>
                           <span class="mr-3 body-2" v-else>
                          -
                          </span>
                        </div>


                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Mês/Ano</span>
                          <span class="mr-3 body-2" v-if="parseFloat(item.tot_vl_parc) > 0">
                          R$ {{ formatNumber(parseFloat(item.tot_vl_parc)) }} ({{ item.quant_parc }})
                          </span>
                        </div>

                         <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Mês/Ano</span>
                          <span class="mr-3 body-2">
                          R$ {{ formatNumber(parseFloat(item.total_geral)) }} ({{ item.quant_geral }})
                          </span>
                        </div>

                      </td>
                    </tr>
                  </tbody>
                </template>

                <!-- <template v-slot:footer>
                  <div class="ml-4 mr-7 mt-1" >
                    <table class="w-100" >
                        <tbody>
                          <tr class="text sticky-table-footer">
                            <td align="center" style="width: 25%; background-color:#FFFFFF"></td>
                            <td align="center"  style="width: 30%; background-color:#FFFFFF">{{ inadimplencia_quant }}</td>
                            <td align="right"  style="width: 45%; background-color:#FFFFFF">R$ {{ formatNumber(inadimplencia_total) }}</td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                </template> -->
              </v-data-table>

              <!-- Rodape  -->
              <div class="mt-4 ml-4 mr-0 container-footer-total">
                <table class="w-100">
                  <tbody>
                    <tr class="text sticky-table-footer-desktop">
                      <td align="center" style="width: 15%; background-color:#FFFFFF"></td>
                      <td align="center" style="width: 25%; background-color:#FFFFFF"><span class="col-font-sumario">R$ {{ formatNumber(inadimplencia_total_entr)}} ({{inadimplencia_quant_entr}})</span></td>
                      <td align="center" style="width: 25%; background-color:#FFFFFF"><span class="col-font-sumario">R$ {{ formatNumber(inadimplencia_total_parc)}} ({{inadimplencia_quant_parc}})</span></td>
                      <td align="center" style="width: 25%; background-color:#FFFFFF"><span class="col-font-sumario">R$ {{ formatNumber(inadimplencia_total_geral)}} ({{inadimplencia_quant_geral}})</span></td>
                    </tr>

                    <tr class="text sticky-table-footer-mobile w-100">
                      <td class="d-flex justify-space-between w-100">
                        <span>Entradas</span>
                        R$ {{ formatNumber(inadimplencia_total_entr)}} ({{inadimplencia_quant_entr}})
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Parcelas</span>
                        R$ {{ formatNumber(inadimplencia_total_parc)}} ({{inadimplencia_quant_parc}})
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Total Geral</span>
                        R$ {{ formatNumber(inadimplencia_total_geral)}} ({{inadimplencia_quant_geral}})
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- RECEBIMENTO ----------------------------------------------------------- -->
      <v-row class="w-100 ma-0">
        <v-col sm="12" lg="12" style="marine" class="px-0">
          <v-card  elevation="1">
            <v-card-text class="text-h6 mb-3"><span class="cor_titulo_painel">Liquidação (Recebidos)</span>
              <table class="d-flex">
                <tr class="row-date pt-0 mt-n1">
                  <td class="label-date-solo">
                    <label class="mb-1 mr-4 h6 label-date">De </label>
                  </td>
                  <td class="td-mobile">
                    <label class="mt-3 mr-6 h6 d-flex d-sm-none label-date">De </label>
                    <v-text-field
                      class="data-input"
                      type="date"
                      v-model="data_recebimento_inicial"
                      dense
                      :value="data_recebimento_inicial"
                    ></v-text-field>
                  </td>
                  <td class="label-date-solo">
                    <label class="mb-1 ml-4 mr-4 h6 label-date">Até</label>
                  </td>
                  <td class="td-mobile">
                    <label class="mt-3 mr-5 h6 d-flex d-sm-none label-date">Até </label>

                    <v-text-field
                    class="data-input"
                      type="date"
                      v-model="data_recebimento_final"
                      dense
                      :value="data_recebimento_final"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-card-text>

            <div class="ml-4 mr-4">
            <v-data-table
              :headers="headers_previsao_recebimento"
              :items="recebimento"
              disable-pagination
              class="elevation-0 table-desktop mb-3"
              mobile-breakpoint="0"
              height="355"
              hide-default-footer
              fixed-header
              :hide-default-header="isMobile"
              fixed-footer
              :loading="loading_recebimento"
              style="margin-top:-30px"
              dense
            >
              <template v-slot:body="{ items }">
                <tbody v-if="!isMobile">
                  <tr v-for="item in items" :key="item.id">
                    <td align="center" style="width: 20%" class="col-font">
                      {{ item.mes_ano }}
                    </td>
                    <td align="right" style="width: 10%" class="col-font">
                      {{ item.tot_entr_qtd }}
                    </td>
                    <td align="right" style="width: 20%" class="col-font">
                      R$ {{ formatNumber(parseFloat(item.tot_entr_valor)) }}
                    </td>
                    <td align="right" style="width: 10%" class="col-font">
                      {{ item.tot_parc_qtd }}
                    </td>
                    <td align="right" style="width: 20%" class="col-font">
                      R$ {{ formatNumber(parseFloat(item.tot_parc_valor)) }}
                    </td>
                    <td align="right" style="width: 25%" class="col-font">
                      R$ {{ formatNumber(parseFloat(item.tot_geral)) }}
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="isMobile" class="tbody-mobile w-100">
                    <tr v-for="item in items" :key="item.id" class="w-100" style="height: 200px;">
                      <td class="w-100 d-flex flex-column justify-space-between mb-3 py-2" style="height: 200px;">
                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Mês/Ano</span>
                          <span class="mr-3 body-2">{{ item.mes_ano }}</span>
                        </div>
                        
                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Entradas</span
                          > 
                          <span class="mr-3 body-2">
                          {{ item.tot_parc_qtd }}</span
                          >
                        </div>

                        <div class="d-flex justify-space-between">
                            <span class="font-weight-bold body-2 ml-3"
                            >Valor Entradas</span
                            >
                            <span class="mr-3 body-2">
                            R$
                            {{
                            formatNumber(parseFloat(item.tot_entr_valor))
                            }}</span >
                        </div>

                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Parcelas</span
                          >
                          <span class="mr-3"> {{ item.tot_parc_qtd }}</span>
                        </div>

                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3">
                          Valor Parcelas</span
                          >
                          <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_parc_valor))
                          }}</span
                          >
                        </div>

                        <div class="d-flex justify-space-between">
                           <span class="font-weight-bold body-2 ml-3">
                            Total Geral</span
                            >
                            <span class="mr-3">
                             R$
                            {{ formatNumber(parseFloat(item.tot_geral)) }}</span
                             >
                        </div>
                      </td>

                      <!-- <td class="w-100 d-flex justify-space-between mb-3">
                        <span class="font-weight-bold body-2 ml-3"
                          >Entradas</span
                        >
                        <span class="mr-3 body-2">
                          {{ item.tot_parc_qtd }}</span
                        >
                      </td> -->

                      <!-- <td class="w-100 d-flex justify-space-between mb-3">
                        <span class="font-weight-bold body-2 ml-3"
                          >Valor Entradas</span
                        >
                        <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_entr_valor))
                          }}</span
                        >
                      </td> -->

                      <!-- <td class="w-100 d-flex justify-space-between mb-3">
                        <span class="font-weight-bold body-2 ml-3"
                          >Parcelas</span
                        >
                        <span class="mr-3"> {{ item.tot_parc_qtd }}</span>
                      </td> -->

                      <!-- <td class="w-100 d-flex justify-space-between mb-3">
                        <span class="font-weight-bold body-2 ml-3">
                          Valor Parcelas</span
                        >
                        <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_parc_valor))
                          }}</span
                        >
                      </td> -->

                      <!-- <td
                        class="
                          w-100
                          d-flex
                          justify-space-between
                          mb-3
                          pb-3
                          last-tr
                        "
                      >
                        <span class="font-weight-bold body-2 ml-3">
                          Total Geral</span
                        >
                        <span class="mr-3">
                          R$
                          {{ formatNumber(parseFloat(item.tot_geral)) }}</span
                        >
                      </td> -->
                    </tr>
                  </tbody>

              </template>

            </v-data-table>

              <!-- <v-data-table
                :items="recebimento"
                disable-pagination
                class="elevation-0 table-mobile"
                mobile-breakpoint="0"
                height="306"
                hide-default-footer
                fixed-fooder
                :loading="loading_recebimento"
                dense
                single-line="false"
              >
                <template v-slot:body="{ items }">
                  <tbody class="tbody-mobile w-100 pa-3">
                    <tr v-for="item in items" :key="item.id" class="w-100 pt-3">
                      <td class="w-100 d-flex justify-space-between mb-3 pt-2">
                        <span class="font-weight-bold body-2 ml-3"
                          >Mês/Ano</span
                        >
                        <span class="mr-3 body-2">{{ item.mes_ano }}</span>
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3">
                        <span class="font-weight-bold body-2 ml-3"
                          >Entradas</span
                        >
                        <span class="mr-3 body-2">
                          {{ item.tot_parc_qtd }}</span
                        >
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3">
                        <span class="font-weight-bold body-2 ml-3"
                          >Valor Entradas</span
                        >
                        <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_entr_valor))
                          }}</span
                        >
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3">
                        <span class="font-weight-bold body-2 ml-3"
                          >Parcelas</span
                        >
                        <span class="mr-3"> {{ item.tot_parc_qtd }}</span>
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3">
                        <span class="font-weight-bold body-2 ml-3">
                          Valor Parcelas</span
                        >
                        <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_parc_valor))
                          }}</span
                        >
                      </td>

                      <td
                        class="
                          w-100
                          d-flex
                          justify-space-between
                          mb-3
                          pb-3
                          last-tr
                        "
                      >
                        <span class="font-weight-bold body-2 ml-3">
                          Total Geral</span
                        >
                        <span class="mr-3">
                          R$
                          {{ formatNumber(parseFloat(item.tot_geral)) }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table> -->

              <!-- RODAPE TABELA  -->
              <div class="ml-4 mr-0 container-footer-total pt-1 pb-4 ">
                <table class="w-100">
                  <tbody>
                    <tr class="text sticky-table-footer-desktop">
                      <td align="center" style="width: 20%; background-color:#FFFFFF"></td>
                      <td align="right" style="width: 10%; background-color:#FFFFFF"><span class="col-font-sumario">
                        {{ recebimento_entr_quant }}</span>
                      </td>
                      <td align="right" style="width: 20%; background-color:#FFFFFF"><span class="col-font-sumario">
                        R$ {{ formatNumber(recebimento_entr_total) }}</span>
                      </td>
                      <td align="right" style="width: 10%; background-color:#FFFFFF"><span class="col-font-sumario">
                        {{ recebimento_parc_quant }}</span>
                      </td>
                      <td align="right" style="width: 20%; background-color:#FFFFFF"><span class="col-font-sumario">
                        R$ {{ formatNumber(recebimento_parc_total) }}</span>
                      </td>
                      <td align="right" style="width: 25%; background-color:#FFFFFF"><span class="col-font-sumario">
                        R$ {{ formatNumber(recebimento_tot_geral) }}</span>

                      </td>
                    </tr>

                    <tr class="text sticky-table-footer-mobile w-100">
                      <td class="d-flex justify-space-between w-100">
                        <span>Entradas</span>
                        {{ recebimento_entr_quant }}
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Valor Entradas</span>
                        R$ {{ formatNumber(recebimento_entr_total) }}
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Parcelas</span>
                        {{ recebimento_parc_quant }}
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Valor Parcelas</span>
                        R$ {{ formatNumber(recebimento_parc_total) }}
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Total Geral</span>
                        R$ {{ formatNumber(recebimento_tot_geral) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- PREVISAO DE RECEBIMENTO ----------------------------------------------------------- -->
      <v-row class="w-100 ma-0">
        <v-col sm="12" lg="12" style="marine" class="px-0">
          <v-card elevation="1">
            <v-card-text class="text-h6  mb-3"><span class="cor_titulo_painel">Previsão de Recebimento</span>
              <table class="d-flex">
                <tr class="row-date pt-0 mt-n1">
                  <td class="label-date-solo">
                    <label class="mb-1 mr-4 h6 label-date">De </label>
                  </td>
                  <td class="td-mobile">
                    <label class="mt-3 mr-6 h6 d-flex d-sm-none label-date">De </label>
                    <v-text-field
                      class="data-input"
                      type="date"
                      v-model="data_previsao_recebimento_inicial"
                      dense
                      :value="data_previsao_recebimento_inicial"
                    ></v-text-field>
                  </td>
                  <td class="label-date-solo">
                    <label class="mb-1 ml-4 mr-4 h6 label-date">Até</label>
                  </td>
                  <td class="td-mobile">
                    <label class="mt-3 mr-5 h6 d-flex d-sm-none label-date">Até </label>

                    <v-text-field
                      class="data-input"
                      type="date"
                      v-model="data_previsao_recebimento_final"
                      dense
                      :value="data_previsao_recebimento_final"
                    ></v-text-field>
                  </td>
                  <td class="label-date-solo ">
                    <label class="mt-4 mb-4 ml-4 mr-4 label-date">Agrupado</label>
                  </td>
                  <td class="td-mobile">
                    <label class="mt-3 mr-5 h6 d-flex d-sm-none label-date">Agrupado</label>
                    <v-select
                      :items="items_agrupado"
                      v-model="previsao_recebimento_agrupado"
                      item-text="nome"
                      item-value="id"
                      class="mb-0 data-input"
                      dense
                    ></v-select>

                  </td>
                </tr>
              </table>
            </v-card-text>

            <div class="ml-4 mr-4">
            <v-data-table
              :headers="headers_previsao_recebimento"
              :items="previsao_recebimento"
              disable-pagination
              class="elevation-0 table-desktop mb-3"
              mobile-breakpoint="0"
              height="355"
              hide-default-footer
              fixed-header
              :hide-default-header="isMobile"
              fixed-fooder
              :loading="loading_previsao_recebimento"
              style="margin-top:-30px"
              dense
            >
              <template v-slot:body="{ items }">
                <tbody v-if="!isMobile" class="">
                  <tr v-for="item in items" :key="item.id">
                    <td align="center" style="width: 20%" class="col-font">
                       {{ previsao_recebimento_agrupado=="MM" ? C_MES_EXTENSO[item.agrupado-1]+" de "+item.ano : item.agrupado }}
                    </td>
                    <td align="right" style="width: 10%" class="col-font">
                      {{ item.tot_entr_qtd }}
                    </td>
                    <td align="right" style="width: 20%" class="col-font">
                      R$ {{ formatNumber(parseFloat(item.tot_entr_valor)) }}
                    </td>
                    <td align="right" style="width: 10%" class="col-font">
                      {{ item.tot_parc_qtd }}
                    </td>
                    <td align="right" style="width: 20%" class="col-font">
                      R$ {{ formatNumber(parseFloat(item.tot_parc_valor)) }}
                    </td>
                    <td align="right" style="width: 25%" class="col-font">
                      R$ {{ formatNumber(parseFloat(item.tot_geral)) }}
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="isMobile" class="tbody-mobile w-100">
                    <tr v-for="item in items" :key="item.id" class="w-100" style="height: 200px;">
                      <td class="w-100 d-flex justify-space-between flex-column mb-3 pt-2 pb-4" style="background-color:#FFFFFF; height: 200px;">
                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Mês/Ano</span
                          >
                          <span class="mr-3 body-2">{{ item.agrupado }}</span>
                        </div>

                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Entradas</span
                          >
                          <span class="mr-3 body-2">
                          {{ item.tot_parc_qtd }}</span
                          >
                        </div>

                        <div class="d-flex justify-space-between">
                            <span class="font-weight-bold body-2 ml-3"
                             >Valor Entradas</span
                             >
                            <span class="mr-3 body-2">
                            R$
                            {{
                            formatNumber(parseFloat(item.tot_entr_valor))
                            }}</span
                            >
                        </div>

                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3"
                          >Parcelas</span
                        >
                          <span class="mr-3"> {{ item.tot_parc_qtd }}</span>
                        </div>

                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3">
                          Valor Parcelas</span
                          >
                          <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_parc_valor))
                          }}</span
                          >
                        </div>

                        <div class="d-flex justify-space-between">
                          <span class="font-weight-bold body-2 ml-3">
                          Total Geral</span
                          >
                          <span class="mr-3">
                          R$
                          {{ formatNumber(parseFloat(item.tot_geral)) }}</span
                          >
                        </div>
                      </td>

                      <!-- <td class="w-100 d-flex justify-space-between mb-3" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3"
                          >Entradas</span
                        >
                        <span class="mr-3 body-2">
                          {{ item.tot_parc_qtd }}</span
                        >
                      </td> -->

                      <!-- <td class="w-100 d-flex justify-space-between mb-3" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3"
                          >Valor Entradas</span
                        >
                        <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_entr_valor))
                          }}</span
                        >
                      </td> -->

                      <!-- <td class="w-100 d-flex justify-space-between mb-3" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3"
                          >Parcelas</span
                        >
                        <span class="mr-3"> {{ item.tot_parc_qtd }}</span>
                      </td> -->

                      <!-- <td class="w-100 d-flex justify-space-between mb-3" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3">
                          Valor Parcelas</span
                        >
                        <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_parc_valor))
                          }}</span
                        >
                      </td> -->

                      <!-- <td class="w-100 d-flex justify-space-between mb-3 pb-3 last-tr">
                        <span class="font-weight-bold body-2 ml-3">
                          Total Geral</span
                        >
                        <span class="mr-3">
                          R$
                          {{ formatNumber(parseFloat(item.tot_geral)) }}</span
                        >
                      </td> -->
                    </tr>
                  </tbody>
              </template>
            </v-data-table>

              <!-- <v-data-table
                :items="previsao_recebimento"
                disable-pagination
                class="elevation-0 table-mobile"
                mobile-breakpoint="0"
                height="306"
                hide-default-footer
                fixed-fooder
                :loading="loading_previsao_recebimento"
                dense
              >
                <template v-slot:body="{ items }">
                  <tbody class="tbody-mobile w-100 pa-3">
                    <tr v-for="item in items" :key="item.id" class="w-100 pt-3">
                      <td class="w-100 d-flex justify-space-between mb-3 pt-2" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3"
                          >Mês/Ano</span
                        >
                        <span class="mr-3 body-2">{{ item.agrupado }}</span>
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3"
                          >Entradas</span
                        >
                        <span class="mr-3 body-2">
                          {{ item.tot_parc_qtd }}</span
                        >
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3"
                          >Valor Entradas</span
                        >
                        <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_entr_valor))
                          }}</span
                        >
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3"
                          >Parcelas</span
                        >
                        <span class="mr-3"> {{ item.tot_parc_qtd }}</span>
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3" style="background-color:#FFFFFF">
                        <span class="font-weight-bold body-2 ml-3">
                          Valor Parcelas</span
                        >
                        <span class="mr-3 body-2">
                          R$
                          {{
                            formatNumber(parseFloat(item.tot_parc_valor))
                          }}</span
                        >
                      </td>

                      <td class="w-100 d-flex justify-space-between mb-3 pb-3 last-tr">
                        <span class="font-weight-bold body-2 ml-3">
                          Total Geral</span
                        >
                        <span class="mr-3">
                          R$
                          {{ formatNumber(parseFloat(item.tot_geral)) }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table> -->

              <!-- RODAPE TABELA -->
              <div class="mt-1 mb-0 ml-4 mr-0 pt-3 pb-4 container-footer-total">
                <table class="w-100">
                  <tbody>
                    <tr class="text sticky-table-footer-desktop">
                      <td align="center" style="width: 20%; background-color:#FFFFFF"></td>
                      <td align="right" style="width: 10%; background-color:#FFFFFF"><span class="col-font-sumario">
                        {{ previsao_recebimento_entr_quant }}</span>
                      </td>
                      <td align="right" style="width: 20%; background-color:#FFFFFF"><span class="col-font-sumario">
                        R$ {{ formatNumber(previsao_recebimento_entr_total) }}</span>
                      </td>
                      <td align="right" style="width: 10%; background-color:#FFFFFF"><span class="col-font-sumario">
                        {{ previsao_recebimento_parc_quant }}</span>
                      </td>
                      <td align="right" style="width: 20%; background-color:#FFFFFF"><span class="col-font-sumario">
                        R$ {{ formatNumber(previsao_recebimento_parc_total) }}</span>
                      </td>
                      <td align="right" style="width: 25%; background-color:#FFFFFF"><span class="col-font-sumario">
                        R$ {{ formatNumber(previsao_recebimento_tot_geral) }}</span>
                      </td>
                    </tr>

                    <tr class="text sticky-table-footer-mobile w-100">
                      <td class="d-flex justify-space-between w-100">
                        <span>Entradas</span>
                        {{ previsao_recebimento_entr_quant }}
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Valor Entradas</span>
                        R$ {{ formatNumber(previsao_recebimento_entr_total) }}
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Parcelas</span>
                        {{ previsao_recebimento_parc_quant }}
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Valor Parcelas</span>
                        R$ {{ formatNumber(previsao_recebimento_parc_total) }}
                      </td>
                      <td class="d-flex justify-space-between w-100">
                        <span>Total Geral</span>
                        R$ {{ formatNumber(previsao_recebimento_tot_geral) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- GRAFICOS -->
    </v-container>
  </div>
</template>


<script>
// this.reservas_abertas = la_dados.filter((o) => o.fase_descricao == "Pre Reserva");
import moment from "moment";
import { API } from "../../services/API";
import { formatNumber, formatPrice, removeMaskMoney, to_Number } from "../../services/funcoes";
import { AbreRelatorioExterno, MenuRelatorioGet } from "../../services/global"
import { C_MES_EXTENSO } from '../../services/constantes'

import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
import { COR_PRINCIPAL } from "../../services/constantes";

// import TipoChart from "../components/Graficos/GraficoTipo.vue";
// import LocalizacaoChart from "../components/Graficos/GraficoLocalizacao.vue";
// import StatusChart from "../components/Graficos/GraficoStatus.vue";
import SituacaoChart from "../../components/Graficos/GraficoSituacao.vue";
import situacaoChartData from "../../components/Graficos/GraficosJs/situacao.js";

import barChartData from "../../components/Graficos/GraficosJs/barra.js";

//import FaturamentoChart from "../../components/Graficos/GraficoFaturamento.vue";
//import precoChartData from "../../components/Graficos/GraficosJs/preco.js";

export default {
  name:"PainelProprietario",

  components: {
    // TipoChart,
    // LocalizacaoChart,
    // StatusChart,
    // PrecoChart,
    SituacaoChart,
    // FaturamentoChart,
  },

  data() {
    return {
      mounted               : false,
      enabled               : null,
      slots                 : ["footer"],
      C_MES_EXTENSO         : C_MES_EXTENSO,
      items_agrupado        : [ {id:"MM"  , nome:"Mensal"},
                                {id:"YYYY", nome:"Anual"}],
      relatorioSelecionado  : {},
      AbreRelatorioExterno  : AbreRelatorioExterno,
      MenuRelatorioGet      : MenuRelatorioGet,
      tree_opcoes           : [],

      COR_PRINCIPAL : COR_PRINCIPAL,

      search_input_focus : false,

      situacaoChartData: situacaoChartData,

      barChartData: barChartData,

      formatNumber: formatNumber,

      intervalo: 1,
      periodo_inicial: null,
      periodo_final: null,

      empreendimentos: [],
      empreendimento_selecionado: {},

      resumo_financeiro: {},

      inadimplencia: [],

      inadimplencia_quant_entr    : 0,
      inadimplencia_total_entr    : 0,
      inadimplencia_quant_parc    : 0,
      inadimplencia_total_parc    : 0,
      inadimplencia_total_geral   : 0,
      inadimplencia_quant_geral   : 0,
      data_inadimplencia_inicial  : null,
      data_inadimplencia_final  : moment().format("YYYY-MM-DD"),
      loading_inadimplencia: true,

      previsao_recebimento                : [],
      previsao_recebimento_entr_quant     : 0,
      previsao_recebimento_entr_total     : 0,
      previsao_recebimento_parc_quant     : 0,
      previsao_recebimento_parc_total     : 0,
      previsao_recebimento_tot_geral      : 0,
      data_previsao_recebimento_inicial   : null,
      data_previsao_recebimento_final     : null,
      previsao_recebimento_agrupado       : "MM",
      loading_previsao_recebimento        : true,

      recebimento: [],
      recebimento_entr_quant: 0,
      recebimento_entr_total: 0,
      recebimento_parc_quant: 0,
      recebimento_parc_total: 0,
      recebimento_tot_geral: 0,
      data_recebimento_inicial : null,
      data_recebimento_final   : null,
      data_recebimento_inicial_menu  : false,
      data_recebimento_final_menu    : false,
      loading_recebimento: true,

      active: [],
      //  files: {
      //   html: 'mdi-language-html5',
      //   js: 'mdi-nodejs',
      //   json: 'mdi-code-json',
      //   md: 'mdi-language-markdown',
      //   pdf: 'mdi-file-pdf',
      //   png: 'mdi-file-image',
      //   txt: 'mdi-file-document-outline',
      //   xls: 'mdi-file-excel',
      // },

      menu_config: [],

      headers: [
        {
          text: "Mês/Ano",
          value: "mes_ano",
          align: "center",
        },
        {
          text: "Entradas",
          value: "tot_vl_entr",
          align: "right",
        },
        {
          text: "Parcelas",
          value: "tot_vl_parc",
          align: "right",
        },
        {
          text: "Total",
          value: "total_geral",
          align: "right",
        },
      ],

      headers_previsao_recebimento: [
        {
          text: "Mês/Ano",
          value: "mes_ano",
          align: "center",
        },
        {
          text: "Entradas",
          value: "tot_entr_qtd",
          align: "right",
        },
        {
          text: "Valor Entradas",
          value: "tot_entr_valor",
          align: "right",
        },
        {
          text: "Parcelas",
          value: "tot_parc_qtd",
          align: "right",
        },
        {
          text: "Valor Parcelas",
          value: "tot_parc_valor",
          align: "right",
        },
        {
          text: "Total Geral",
          value: "tot_geral",
          align: "right",
        },
      ],

    };
  },

  watch: {
    // enabled(slot) {
    //   if (slot === "no-data") {
    //     this.items = [];
    //   } else if (slot === "no-results") {
    //     this.search = "...";
    //   } else {
    //     this.search = null;
    //     this.items = desserts;
    //   }
    // },

    // Mudou o Empreendimento -----------------------------------
    empreendimento_selecionado() {
      this.painel_proprietario(this.empreendimento_selecionado, this.intervalo);
      this.painel_inadimplencia(
        this.empreendimento_selecionado,
        this.data_inadimplencia_inicial,
        this.data_inadimplencia_final
      );
      this.painel_recebimento(
        this.empreendimento_selecionado,
        this.data_recebimento_inicial,
        this.data_recebimento_final
      );
      this.painel_previsao_recebimento(
        this.empreendimento_selecionado,
        this.data_previsao_recebimento_inicial,
        this.data_previsao_recebimento_final,
        this.data_previsao_recebimento_agrupado
      );
    },

    // Mudou Inadimplencia -----------------------------------
    data_inadimplencia_inicial() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_inadimplencia(
            this.empreendimento_selecionado,
            this.data_inadimplencia_inicial,
            this.data_inadimplencia_final
          );
        }, 1000);
      }
    },
    data_inadimplencia_final() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_inadimplencia(
            this.empreendimento_selecionado,
            this.data_inadimplencia_inicial,
            this.data_inadimplencia_final
          );
        }, 1000);
      }
    },

    // Mudou Previsao de Recebimento -----------------------------------
    data_previsao_recebimento_inicial() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_previsao_recebimento(
            this.empreendimento_selecionado,
            this.data_previsao_recebimento_inicial,
            this.data_previsao_recebimento_final,
            this.previsao_recebimento_agrupado
          );
        }, 1000);
      }
    },
    data_previsao_recebimento_final() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_previsao_recebimento(
            this.empreendimento_selecionado,
            this.data_previsao_recebimento_inicial,
            this.data_previsao_recebimento_final,
            this.previsao_recebimento_agrupado
        )
        }, 1000);
      }  
    },
    previsao_recebimento_agrupado() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_previsao_recebimento(
            this.empreendimento_selecionado,
            this.data_previsao_recebimento_inicial,
            this.data_previsao_recebimento_final,
            this.previsao_recebimento_agrupado
        )
        }, 50);
      }
    },

    // Mudou Recebimento -----------------------------------
    data_recebimento_inicial() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_recebimento(
            this.empreendimento_selecionado,
            this.data_recebimento_inicial,
            this.data_recebimento_final
          );
        }, 1000);
      }
    },
    data_recebimento_final() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_recebimento(
            this.empreendimento_selecionado,
            this.data_recebimento_inicial,
            this.data_recebimento_final
        )
        }, 1000);
      }  
    },

  },

  async mounted() {
    
    // Busca provilegios de acesso ao empreendimento
    await this.empreendimento_privilegio_acesso();
    if (this.empreendimentos.length > 0) {
      this.empreendimento_selecionado =
        this.empreendimentos[0].cod_empreendimento;
    }
    
    // Busca menu para esta aplicacao
    await this.menu_relatorio("PainelProprietario");
  },

  methods: {
    async openNode(node) {
      //this.$refs.tree.openall = false;
      //this.$refs.tree.updateAll(true);
      //console.log('------', this.$refs.tree);
    },

    // Abrindo Relatorio Externo
    async abreRelatorioExterno(node) {
      if (node) {
        if (node[0].id) {
          this.$refs.titulo_cabecalho.focus();

          // Foi preciso usar variavel auxiliar para contornar erro do Vuetify quando clica pela segunda vez não pega o Node
          var l_Aux = this.menu_config;
          this.menu_config = [];
          await this.AbreRelatorioExterno(node[0].id);
          this.menu_config = l_Aux;
          //this.$refs.tree.
        }
      }
    },

    async menu_relatorio(p_programa) {
      var la_Res = [];
      la_Res = await this.MenuRelatorioGet(p_programa);
      this.menu_config = la_Res;
      console.log('AAA:: ', JSON.stringify(this.menu_config));
    },

    isEnabled(slot) {
      return this.enabled === slot;
    },

    async empreendimento_privilegio_acesso() {
      const resp = await API.get(`empreendimento_privilegio_acesso`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },

    async painel_inadimplencia(p_codEmpreendimento, p_data_inicial, p_data_final) {
      const datas = { cod_empreendimento  : p_codEmpreendimento,
                      data_inicial      : moment(p_data_inicial, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                      data_final        : moment(p_data_final, 'YYYY-MM-DD').format('DD/MM/YYYY') };
      const resp = await API.get(`painel_inadimplencia`, {
        params: datas,
      });
      if (resp) {
        this.mounted = false;
        this.inadimplencia = resp.data.result.rows;

        if (!this.data_inadimplencia_inicial)
          this.data_inadimplencia_inicial = moment(resp.data.result.data_inicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (!this.data_inadimplencia_final)
          this.data_inadimplencia_final   = moment(resp.data.result.data_final, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.inadimplencia_quant_entr  = this.inadimplencia.reduce((total, item) => total + parseFloat(item.quant_entr), 0);
        this.inadimplencia_total_entr  = this.inadimplencia.reduce((total, item) => total + parseFloat(item.tot_vl_entr), 0);
        this.inadimplencia_quant_parc  = this.inadimplencia.reduce((total, item) => total + parseFloat(item.quant_parc), 0);
        this.inadimplencia_total_parc  = this.inadimplencia.reduce((total, item) => total + parseFloat(item.tot_vl_parc), 0);
        this.inadimplencia_quant_geral = this.inadimplencia.reduce((total, item) => total + parseFloat(item.quant_geral), 0);
        this.inadimplencia_total_geral = this.inadimplencia.reduce((total, item) => total + parseFloat(item.total_geral), 0);

        this.loading_inadimplencia = false;
        this.$nextTick(async () => {
          this.mounted = true;
        })
      }
    },

    async painel_previsao_recebimento(p_codEmpreendimento, p_data_inicial, p_data_final, p_previsao_recebimento_agrupado) {
      this.loading_previsao_recebimento = true;
      if (!p_previsao_recebimento_agrupado) 
        p_previsao_recebimento_agrupado = "MM";
      const datas = { cod_empreendimento: p_codEmpreendimento,
                      data_inicial      : moment(p_data_inicial, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                      data_final        : moment(p_data_final, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                      agrupado          : p_previsao_recebimento_agrupado };
      const resp = await API.get(`painel_previsao_recebimento`, {
        params: datas,
      });
      if (resp) {
        this.previsao_recebimento = resp.data.result.rows;
        this.mounted = false;
        if (!this.data_previsao_recebimento_inicial)
          this.data_previsao_recebimento_inicial = moment(resp.data.result.data_inicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (!this.data_previsao_recebimento_final)
          this.data_previsao_recebimento_final   = moment(resp.data.result.data_final, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.previsao_recebimento_entr_quant = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_entr_qtd), 0);
        this.previsao_recebimento_entr_total = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_entr_valor), 0);
        this.previsao_recebimento_parc_quant = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_parc_qtd), 0);
        this.previsao_recebimento_parc_total = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_parc_valor), 0);
        this.previsao_recebimento_tot_geral  = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_geral), 0);
        this.loading_previsao_recebimento = false;
        this.$nextTick(async () => {
          this.mounted = true;
        })
      }
    },

    async painel_recebimento(p_codEmpreendimento, p_data_inicial, p_data_final) {
      this.loading_recebimento = true;
      const datas = { cod_empreendimento: p_codEmpreendimento,
                      data_inicial      : moment(p_data_inicial, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                      data_final        : moment(p_data_final, 'YYYY-MM-DD').format('DD/MM/YYYY') };
      const resp = await API.get(`painel_recebimento`, {
        params: datas,
      });
      if (resp) {
        this.recebimento = resp.data.result.rows;
        this.mounted = false;
        if (!this.data_recebimento_inicial)
          this.data_recebimento_inicial = moment(resp.data.result.data_inicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (!this.data_recebimento_final)
          this.data_recebimento_final   = moment(resp.data.result.data_final, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.recebimento_entr_quant = this.recebimento.reduce((total, item) => total + parseFloat(item.tot_entr_qtd), 0);
        this.recebimento_entr_total = this.recebimento.reduce((total, item) => total + parseFloat(item.tot_entr_valor), 0);
        this.recebimento_parc_quant = this.recebimento.reduce((total, item) => total + parseFloat(item.tot_parc_qtd), 0);
        this.recebimento_parc_total = this.recebimento.reduce((total, item) => total + parseFloat(item.tot_parc_valor), 0);
        this.recebimento_tot_geral  = this.recebimento.reduce((total, item) => total + parseFloat(item.tot_geral), 0);
        this.loading_recebimento = false;
        this.$nextTick(async () => {
          this.mounted = true;
        })
      }
    },

    async painel_proprietario(p_codEmpreendimento, p_periodo, p_data_inicial, p_data_final) {
      if (p_periodo == 0) {
        // HOJE
        p_data_inicial = moment().format("DD/MM/YYYY");
        p_data_final = moment().format("DD/MM/YYYY");
      } else if (p_periodo == 1) {
        // SEMANA
        p_data_inicial = moment().day(0).format("DD/MM/YYYY");
        p_data_final = moment().day(6).format("DD/MM/YYYY");
      } else if (p_periodo == 2) {
        // MES
        p_data_inicial = moment().startOf("month").format("DD/MM/YYYY");
        p_data_final = moment().endOf("month").format("DD/MM/YYYY");
      } else if (p_periodo == 3) {
        // ANO
        p_data_inicial = moment().startOf("year").format("DD/MM/YYYY");
        p_data_final = moment().endOf("year").format("DD/MM/YYYY");
      } else if (p_periodo == 4) {
        // PERIODO
        p_data_inicial = moment(this.periodo_inicial).format("DD/MM/YYYY");
        if (!p_data_inicial || p_data_inicial == "Invalid date") {
          p_data_inicial = "01/01/1900";
        }
        p_data_final = moment(this.periodo_final).format("DD/MM/YYYY");
        if (!p_data_final || p_data_final == "Invalid date")
          p_data_final = "01/01/2200";
      }

      const datas = { cod_empreendimento: p_codEmpreendimento, data_inicial: p_data_inicial, data_final: p_data_final };
      const resp = await API.get(`painel_proprietario`, {
        params: datas,
      });

      var _this = this;
      var resp_data;

      // Montando Gráfico da Situação --------------------------------------------------------
      if (resp && resp.data.result.resumo_financeiro) {
        this.resumo_financeiro = resp.data.result.resumo_financeiro[0]
      }

      // Montando Gráfico da Situação --------------------------------------------------------
      if (resp && resp.data.result.situacao) {
        resp_data = resp.data.result.situacao;
        this.situacaoChartData.data.labels = [];
        this.situacaoChartData.data.datasets[0].data = [];
        this.situacaoChartData.data.datasets[0].label = [];
        this.situacaoChartData.data.datasets[0].backgroundColor = [];
        this.situacaoChartData.labels = resp_data;
        resp_data.map(function (value) {
          _this.situacaoChartData.data.labels.push(value.situacao_nome + ' - ' + value.count);
          _this.situacaoChartData.data.datasets[0].data.push(value.count);
          _this.situacaoChartData.data.datasets[0].backgroundColor.push(value.situacao_venda_cor_html);
        });
        const ctx = document.getElementById("situacao-chart");
        const ctx_parent = ctx.parentElement;
        while (ctx_parent.firstChild) {
          ctx_parent.removeChild(ctx_parent.firstChild);
        }
        var canv = document.createElement("canvas");
        canv.setAttribute("id", "situacao-chart");
        canv.setAttribute("style", "opacity:0.8; background-color:#FFFFFF; position: relative; max-height:40vh; width:80vw");
        //max-height="300px" style="position: relative; height:40vh; width:80vw">
        ctx_parent.appendChild(canv);
        new Chart(canv, this.situacaoChartData);
      }

      // Montando Gráfico de Inadimplencia --------------------------------------------------------      
      if (resp && resp.data.result.inadimplencia) {
        //this.inadimplencia = resp.data.result.inadimplencia;

        //this.inadimplencia_quant = resp.data.result.inadimplencia.reduce((total, item) => total + parseFloat(item.quant), 0);
        //this.inadimplencia_total = resp.data.result.inadimplencia.reduce((total, item) => total + parseFloat(item.tot_vl_parc), 0);

        // resp_data = resp.data.result.inadimplencia;
        // this.barChartData.data.labels = [];
        // this.barChartData.data.datasets[0].data = [];
        // this.barChartData.data.datasets[0].label = [];
        // this.barChartData.data.datasets[0].backgroundColor = [];
        // this.barChartData.labels = resp_data;
        // resp_data.map(function (value) {
        //   _this.barChartData.data.labels.push(value.mes_ano);
        //   _this.barChartData.data.datasets[0].data.push(value.quant);
        //   //_this.barChartData.data.datasets[0].backgroundColor.push(value.situacao_venda_cor_html);
        // });
        // const ctx = document.getElementById("grafico-barra");
        // const ctx_parent = ctx.parentElement;
        // while (ctx_parent.firstChild) {
        //   ctx_parent.removeChild(ctx_parent.firstChild);
        // }
        // var canv = document.createElement("canvas");
        // canv.setAttribute("id", "grafico-barra");
        // ctx_parent.appendChild(canv);
        // new Chart(canv, this.barChartData);
      }

    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },
  },

  
    computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    
    // tableHeight() {
    //   if (this.isMobile)
    //     return window.innerHeight - 149 + 30 - 90;
    //   else
    //     return window.innerHeight - 149 - 90;
    // }
},

};
</script>


<style scoped>
*,
a {
  text-decoration: none;
}

.card-price {
  background: #274abb;
  color: #fff !important;
}

.card-price span {
  color: #fff !important;
  opacity: 0.8;
  font-size: 20px;
}

.card-location {
  background: #4bc0c0;
  color: #fff !important;
}

.card-location span {
  opacity: 0.8;
  font-size: 20px;
}

.card-status {
  background: #9966ff;
  color: #fff !important;
}

.card-status span {
  opacity: 0.8;
  font-size: 20px;
}

.card-type {
  background: #519e8a;
  color: #fff !important;
}

.card-type span {
  opacity: 0.8;
  font-size: 20px;
}

.card-price .body-2,
.card-location .body-2,
.card-status .body-2,
.card-type .body-2 {
  color: #fff !important;
  opacity: 0.9;
}

.card-type span {
  background: #389f83;
}

.body-2 {
  display: flex;
  justify-content: space-between;
  font-size: 13px !important;
}

.body-3 {
  font-size: 16px !important;
  color: #fff;
}

.icon-dashboard {
  font-size: 2.2rem;
  color: #fff;
  opacity: 0.6;
}

.card-price span,
.card-location span,
.card-status span,
.card-type span {
  margin-top: -12px;
  padding-left: 16px;
}

.card-container {
  overflow-x: auto;
}

@media (max-width: 599px) {
  .card-container {
    margin-top: 24px;
  }
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.sticky-table-footer-mobile {
  display: none;
}

@media (max-width: 599px) {
  .sticky-table-footer-desktop {
    display: none;
  }

  .container-footer-total {
    padding-right: 0px!important;
    padding-left: 0px!important;
  }

  .sticky-table-footer-mobile {
    display: block;
  }

  .sticky-table-footer-mobile td {
    margin-top: 10px;
    padding: 4px;
    font-weight: bold;
    position: sticky;
    bottom: 0;
    background-color: #f8f8f8;
    color: #3268a3;
    font-size: 14px;
  }
}

.main-title {
  background: #0548a3;
}

.text-white {
  font-size: 20px;
  color: #FFFFFF !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/
@media (max-width: 599px) {
  .container-table {
    padding: 0px 8px !important;
  }
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

.search-input {
  margin: 0.8rem 50% 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }
}

@media (max-width: 599px) {
  .search-input {
    width: 200px;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    min-width: 100%;
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

.container-content {
  border-radius: 20px 20px !important;
  background: #fff !important;
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 4px !important;
    padding-left: 2px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

@media (max-width: 599px) {
  .container-content {
    padding: 16px !important;
  }
}

.container-table {
  max-width: 100% !important;
  border-radius: 16px 16px 0px 0px;
  background: #f2f6f7;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.height {
  height: 513px !important;
}

@media (min-width: 1441px) {
  .height {
    height: initial !important;
  }
}

@media (max-width: 768px) {
  .height {
    height: initial !important;
  }
}

.row-date {
  padding-top: 18px !important;
}

@media (max-width: 599px) {
  .row-date {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .td-mobile {
    display: flex;
  }

  .label-date-solo {
    display: none;
  }
}

.label-date {
  font-size: 14px;
  font-weight: 500;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px !important;
}

@media(min-width: 1441px) {
  .col-inadimplencia .min-height {
    /* height: 733px!important; */
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 1024px) {
  .col-inadimplencia,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .col-inadimplencia,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.table-mobile {
  display: none;
}

@media (max-width: 599px) {
  .table-mobile {
    display: block;
  }

  .tbody-mobile {
    padding: 12px !important;
  }

  .font-weight-bold {
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.6);
  }
}

.last-tr {
  border-bottom: 1px solid #dddcdc !important;
}

.cor_titulo_painel {
  font-size: 18px !important;
  color: #3268a3;
}

.col-font-sumario {
  font-size: 15px !important;
  color: #3268a3;
}

.col-font {
  font-size:13px !important
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250 ,250, 250);
}

.w-100 {
  width: 100%;
}

.card-size {
min-width: 150px; 
  height: 100px;
}

.date-mobile {
  margin-top: -16px!important;
  margin-bottom: 34px!important;
}

.data-input {
  font-weight: 400;
  font-size: 15px;
}
</style>


